.image {
    &.flex {
        display: flex;
    }

    &.flex-row {
        flex-direction: row;
    }

    &.flex-col {
        flex-direction: column;
    }

    &.flex.justify-start {
        justify-content: flex-start;
    }

    &.flex.justify-end {
        justify-content: flex-end;
    }

    &.flex.justify-normal {
        justify-content: normal;
    }

    &.flex.justify-center {
        justify-content: center;
    }

    &.flex.justify-between {
        justify-content: space-between;
    }

    &.flex.justify-around {
        justify-content: space-around;
    }

    &.flex.justify-evenly {
        justify-content: space-evenly;
    }

    &.flex.justify-stretch {
        justify-content: stretch;
    }

    &.flex.items-center {
        align-items: center;
    }
}