.scale-to-width img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.scale-to-height img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
}

.origin-center {
  transform-origin: center;
}
.origin-bottom {
  transform-origin: bottom;
}
.origin-top {
  transform-origin: top;
}
.origin-left {
  transform-origin: left;
}
.origin-right {
  transform-origin: right;
}

@media screen and (min-width: $screen-desktop) {
  .d-scale-80 {
    transform: scale(0.8);
  }
  .d-scale-85 {
    transform: scale(0.85);
  }
  .d-scale-90 {
    transform: scale(0.9);
  }
  .d-scale-95 {
    transform: scale(0.95);
  }
  .d-scale-105 {
    transform: scale(1.05);
  }
  .d-scale-110 {
    transform: scale(1.1);
  }
  .d-scale-115 {
    transform: scale(1.15);
  }
  .d-scale-120 {
    transform: scale(1.2);
  }
  .d-scale-125 {
    transform: scale(1.25);
  }
  .d-scale-130 {
    transform: scale(1.3);
  }
}
