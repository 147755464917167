.text-xs {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
.text-sm {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.text-base {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.text-lg {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
.text-xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.text-2xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.text-3xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.text-4xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
.text-5xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 2.5rem;
    line-height: 1;
  }
}
.text-6xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 3.75rem;
    line-height: 1;
  }
}
.text-7xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 4.5rem;
    line-height: 1;
  }
}
.text-8xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 6rem;
    line-height: 1;
  }
}
.text-9xl {
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 8rem;
    line-height: 1;
  }
}

@media screen and (max-width: $screen-desktop) {
  .m-break-collapse br {
    display: none;
  }

  div[class*="text-"].m-text-xs {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
  div[class*="text-"].m-text-sm {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  div[class*="text-"].m-text-base {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  div[class*="text-"].m-text-lg {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  div[class*="text-"].m-text-xl {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  div[class*="text-"].m-text-2xl {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  div[class*="text-"].m-text-3xl {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }
  div[class*="text-"].m-text-4xl {
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  }
}