.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: $screen-desktop) {
  .m-text-left,
  .title.m-text-left {
    text-align: left;
  }
  .m-text-right,
  .title.m-text-right {
    text-align: right;
  }
}
