.column-1 {
    width: $column-1;
}

.column-2 {
    width: $column-2;
}

.column-3 {
    width: $column-3;
}

.column-4 {
    width: $column-4;
}

.column-5 {
    width: $column-5;
}

.column-6 {
    width: $column-6;
}

.column-7 {
    width: $column-7;
}

.column-8 {
    width: $column-8;
}

.column-9 {
    width: $column-9;
}

.column-10 {
    width: $column-10;
}

.column-11 {
    width: $column-11;
}

.column-12 {
    width: $column-12;
}

@media screen and (max-width: $screen-desktop) {
    .m-column-1 {
        width: $column-1;
    }

    .m-column-2 {
        width: $column-2;
    }

    .m-column-3 {
        width: $column-3;
    }

    .m-column-4 {
        width: $column-4;
    }

    .m-column-5 {
        width: $column-5;
    }

    .m-column-6 {
        width: $column-6;
    }

    .m-column-7 {
        width: $column-7;
    }

    .m-column-8 {
        width: $column-8;
    }

    .m-column-9 {
        width: $column-9;
    }

    .m-column-10 {
        width: $column-10;
    }

    .m-column-11 {
        width: $column-11;
    }

    .m-column-12 {
        width: $column-12;
    }


    .m-rows-1 {
        height: $column-1;
    }

    .m-rows-2 {
        height: $column-2;
    }

    .m-rows-3 {
        height: $column-3;
    }

    .m-rows-4 {
        height: $column-4;
    }

    .m-rows-5 {
        height: $column-5;
    }

    .m-rows-6 {
        height: $column-6;
    }

    .m-rows-7 {
        height: $column-7;
    }

    .m-rows-8 {
        height: $column-8;
    }

    .m-rows-9 {
        height: $column-9;
    }

    .m-rows-10 {
        height: $column-10;
    }

    .m-rows-11 {
        height: $column-11;
    }

    .m-rows-12 {
        height: $column-12;
    }

    .m-rows-auto {
        height: auto;
    }
}