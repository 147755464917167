.title {
  &.text-gray {
    color: $color-gray;
  }

  &.text-lime {
    color: $color-lime;
  }

  &.text-strawberry {
    color: $color-strawberry;
  }

  &.text-colada {
    color: $color-colada;
  }
}