.hide-on-desktop {
    display: none;
}

.hide-on-mobile {
    display: block;
}

.hide-on-mobile.flex {
    display: flex;
}

@media screen and (max-width: $screen-desktop) {
    .hide-on-desktop {
        display: block;
    }

    .hide-on-desktop.flex {
        display: flex;
    }

    .hide-on-mobile.flex,
    .hide-on-mobile.flex.column-horizontal-right,
    .hide-on-mobile.flex.column-horizontal-left,
    .hide-on-mobile.flex.column-horizontal-center,
    .hide-on-mobile.column-vertical-center.flex {
        display: none;
    }
}