.header-footer-margin {
    padding-top: $height-header;
    padding-bottom: $height-footer;
}

.p-1 {
    padding: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-3 {
    padding: 0.75rem;
}

.p-4 {
    padding: 1rem;
}

.pl-4 {
    padding-left: 1rem;
}

.pr-4 {
    padding-left: 1rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pt-3.container>.cmp-container {
    padding-top: 0.75rem;
}

.pb-3.container>.cmp-container {
    padding-bottom: 0.75rem;
}

.py-3.container>.cmp-container {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.py-4.container>.cmp-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-5.container>.cmp-container {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.py-6.container>.cmp-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-7.container>.cmp-container {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}

.py-8.container>.cmp-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.px-2.container>.cmp-container,
.px-2.text>.cmp-text {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-4.container>.cmp-container,
.px-4.text>.cmp-text {
    padding-left: 1rem;
    padding-right: 1rem;
}


.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.ml-10 {
    margin-left: 2.5rem;
}

@media screen and (max-width: $screen-desktop) {

    .m-pt-2 {
        padding-top: 0.5rem;
    }

    .m-pt-4 {
        padding-top: 1rem;
    }

    div[class*="px-"].m-px-unset>.cmp-container {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    div[class*="px-"].m-px-2>.cmp-container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    div[class*="px-"].m-py-unset>.cmp-container {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}