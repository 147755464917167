.product-overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;

    .cmp-title {
      @media screen and (max-width: $screen-desktop) {
        margin-bottom: .25rem;
      }
    }

    &.text-xl h3.cmp-title__text {
        @media screen and (max-width: $screen-xl) {
        font-size: 1.15rem;
        line-height: 1.55rem;
      }

      @media screen and (max-width: $screen-desktop) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
  
