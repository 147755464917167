.no-fuss-column {
  justify-content: center;
  justify-content: flex;
  display: flex;
  margin: 1rem 0 0;
  width: 104px;
  height: 104px;

  @media screen and (max-height: $screen-v-break) {
    height: 11vh;
    width: 11vh;
  }

}

.logo-image {
  width: 98px;
  height: 114px;
  max-width: 98px;
  max-height: 114px;

  @media screen and (max-height: $screen-v-break) {
    height: 14vh;
    width: 43vw;
  }

  .cmp-image {
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.logo-image-large {
  width: 138px;
  height: 144px;
  max-width: 138px;
  max-height: 144px;

  @media screen and (max-height: $screen-v-break) {
    height: 17vh;
    width: 43vw;
  }

  .cmp-image {
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.product-image-container {
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    height: 100%;

    .cmp-image {
      display: flex;
      justify-content: center;
      max-height: 100%;
      max-width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
