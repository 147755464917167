//== XF Footer style, used on page template

.main-footer > .cmp-container {
  position: relative;
  scroll-snap-align: start;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: $color-background;
  box-sizing: border-box;

  .contains-alcohol {
    display: block;
    height: $height-alcohol-icon;
    width: $width-alcohol-icon;
    color: transparent;
    background-size: contain;
    * {
      width: 100%;
      height: 100%;
    }
  }

  .social-icon {
    display: block;
    height: $height-social-icon;
    width: $width-social-icon;
    color: transparent;
    background-size: contain;
    * {
      width: 100%;
      height: 100%;
    }
  }

  .footer-nav-container {
    .button {
      .cmp-button {
        padding: 0;
        margin: 0em 0 0 1.5em;
      }
    }
  }

  @media screen and (max-width: $screen-xl) {
    .text.text-center.m-0 {
      .cmp-text {
        margin: 0.25rem 0 0;
      }
    }
    .text.text-center.text-xs.m-0 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    [class*="column-"]:nth-child(1) {
      position: absolute;
      left: 1rem;
      top: 1rem;
      width: 60px;
    }
    [class*="column-"]:nth-child(2) {
      width: 100%;
      flex-grow: 1;

      .container:nth-child(1) > .cmp-container:nth-child(1) {
        margin-top: 25px;
      }
     .container:nth-child(1) > .cmp-container {
        margin-bottom: 25px;
      }
     .container:nth-child(2) .text {
        margin-bottom: 10px;
      }
    }
    [class*="column-"]:nth-child(3) {
      width: 0px;
    }
  }
}
