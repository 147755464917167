.button {
    &.flex {
      display: flex;
  
      &.flex-row {
        flex-direction: row;
      }
  
      &.flex-col {
        flex-direction: column;
      }
  
      &.justify-end {
        justify-content: flex-end;
      }
  
      &.justify-center {
        justify-content: center;
      }
    }
  }
  