.container.h-full > .cmp-container {
  height: 100%;
}

.container.flex > .cmp-container {
  display: flex;
}

.container.flex-row > .cmp-container {
  flex-direction: row;
}

.container.flex-col > .cmp-container {
  flex-direction: column;
}

@media screen and (max-width: $screen-desktop) {
  .container.flex-row.m-flex-col > .cmp-container {
    flex-direction: column;
  }

  .container.flex-col.m-flex-row > .cmp-container {
    flex-direction: row;
  }
  .container.flex.flex-col,
  .container.flex.flex-row {
    &.m-justify-start > .cmp-container {
      justify-content: flex-start;
    }
    &.m-justify-end > .cmp-container {
      justify-content: flex-end;
    }
    &.m-justify-center > .cmp-container {
      justify-content: center;
    }
    &.m-items-center > .cmp-container {
      align-items: center;
    }
    &.m-items-start > .cmp-container {
      align-items: flex-start;
    }
    &.m-items-end > .cmp-container {
      align-items: flex-end;
    }
  }

  .container.fit-in-viewport > .cmp-container {
    max-width: calc(100vh - 295px);
  }
}

.container {
  &.flex {
    &.flex-wrap > .cmp-container {
      flex-wrap: wrap;
    }

    &.justify-start > .cmp-container {
      justify-content: flex-start;
    }

    &.justify-end > .cmp-container {
      justify-content: flex-end;
    }

    &.justify-normal > .cmp-container {
      justify-content: normal;
    }

    &.justify-center > .cmp-container {
      justify-content: center;
    }

    &.justify-between > .cmp-container {
      justify-content: space-between;
    }

    &.justify-around > .cmp-container {
      justify-content: space-around;
    }

    &.justify-evenly > .cmp-container {
      justify-content: space-evenly;
    }

    &.justify-stretch > .cmp-container {
      justify-content: stretch;
    }

    &.items-center > .cmp-container {
      align-items: center;
    }

    &.items-start > .cmp-container {
      align-items: flex-start;
    }
  }
}

.w-full,
.w-full > .cmp-container {
  width: 100%;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-xs {
  max-width: 640px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}
