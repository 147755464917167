.iframe-full-width {
  width: 100%;

  .cmp-embed {
    width: 100%;

    .cmp-embed__vip__container {
      width: 100%;

      iframe {
        width: 100%;
      }
    }
  }
}

.iframe-full-height {
  height: 100%;

  .cmp-embed {
    height: 100%;

    .cmp-embed__vip__container {
      height: 100%;

      iframe {
        height: 100%;
      }
    }
  }
}

.iframe-no-border {
  iframe {
    border: 0;
  }
}