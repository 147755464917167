.insta-image-block {
    position: relative;
    width: 33.3333%;
    height: 33.3333%;
  }
  
  .hide-insta-ui .cmp-embed {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  
    .cmp-embed__instagram {
      width: 100%;
      iframe {
        position: absolute;
        top: $top-embed-insta-header;
        width: 100% !important;
        min-width: unset !important;
        margin: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
      }
    }
  }