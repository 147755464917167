//== Defaults

html,
body {
  margin: 0;
  color: $color-text;
  font-size: $font-size;
  font-family: defaultFont, Arial, sans-serif;
  font-weight: bold;
  line-height: $font-height;
  background: $color-background;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html:not(.aem-AuthorLayer-Edit) {
  position: relative;
  body {
    position: relative;
  }
}

html:not(.aem-AuthorLayer-Edit) a {
  color: $color-link;
}

.cmp-experiencefragment--footer p {
  margin-block: 0;
}
p {
  margin-block: 0.75rem;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: $screen-md) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}
