.age-gate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  &.age-gate-hidden {
    display: none;
  }

  *:not(span) {
    color: #000;
  }

  >.cmp-container {
    width: 377px;
    background-color: #e7e7e8;
    box-sizing: border-box;
    padding: 0.75rem;

    @media screen and (min-width: $screen-2xl) {
      position: absolute;
      right: calc((100vw - 1536px) / 2);
      margin: 1rem;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    a {
      text-decoration: underline;
      color: #000;
    }

    .cmp-form-text {
      margin: 0.25em;
    }

    .cmp-form-text label {
      font-size: 0.85em;
      margin-bottom: 0;
      text-transform: none;
      color: #000;
    }

    div[class^="age-gate-dob__"]:nth-child(1) {
      margin-left: 0;
    }

    div[class^="age-gate-dob__"]:nth-child(3) {
      margin-right: 0;
    }

    .age-gate-dob__dropdown--month,
    .age-gate-dob__dropdown--day {
      input {
        box-sizing: border-box;
        width: 90px;
        height: 44px;
      }
    }

    .age-gate-dob__dropdown--year {
      input {
        box-sizing: border-box;
        width: 134px;
        height: 44px;
      }
    }

    .age-gate-confirm {
      display: flex;
      justify-content: center;
      align-self: center;

      a {
        background-color: #000;
        color: #fff;
        text-decoration: none;

        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
    }
  }
}