.cmp-button {
  width: fit-content;
  height: fit-content;
  margin: 0.5em 0.5em;
  padding: 0.5625em 1em 0.6875em 1em;
  border-style: solid;
  border-width: 0.125em;
}

.button .cmp-button__icon {
  display: block;
  border-width: 0;
  background-color: transparent;
  color: #fff;
}

// .button.plain .cmp-button
.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}

.hide-button-text span.cmp-button__text {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.button-image .cmp-button {
  padding: 0;
  margin: 0;
}