.object-contain img {
    object-fit: contain;
}

.object-center img {
    object-position: center;
}

.object-bottom img {
    object-position: bottom;
}