.title.red-strikethrough {
    .cmp-title {
        display: flex;

        h3:before {
            content: "";
            display: block;
            width: calc(100% + 18px);
            border-top: 6px solid $color-red-strikethrough;
            position: relative;
            top: 1.15rem;
            left: -9px;
        }
    }

    .cmp-title__text {
        display: inline;
    }
}