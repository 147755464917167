.button.plain .cmp-button,
.button.icon .cmp-button {
  border-width: 0;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  background-repeat: no-repeat;
}

.button.icon {
  a {
    text-decoration: none;
  }

  .cmp-button .cmp-button__text {
    color: transparent;
    font-size: 0px;
  }
}