.container {
  &.column-vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.column-horizontal-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &.column-horizontal-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &.column-horizontal-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &.fit-in-viewport > .cmp-container {
    max-width: calc(100vh - $height-header);
  }

  &.scale-to-content > .cmp-container {
    height: auto;
  }
}

@media screen and (max-width: $screen-desktop) {
    div[class*="column-horizontal"].container {
        &.m-column-vertical-center {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      
        &.m-column-horizontal-left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }
      
        &.m-column-horizontal-center {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      
        &.m-column-horizontal-right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
}
