.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

@media screen and (max-width: $screen-desktop) {

    .m-absolute {
        position: absolute;
    }

    .m-relative {
        position: relative;
    }

    .m-fixed {
        position: fixed;
    }
}