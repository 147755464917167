html:not(.aem-AuthorLayer-Edit) {
    #buy.cmp-container {
      box-sizing: border-box;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      .container {
        height: 100%;
        width: 100%;
        position: relative;
        #iframe-container {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
      .aem-GridColumn.page-section-full {
        > .cmp-container {
          padding: 0;
        }
      }
    }
    .aem-GridColumn.page-section-full {
      display: flex;
      justify-content: center;
      flex-direction: row;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
  
      > .cmp-container:not(#buy) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 1rem;
      }
      > .cmp-container {
        height: 100%;
        width: 100%;
  
        box-sizing: border-box;
        z-index: 1;
  
        > .aem-Grid {
          height: 100%;
          width: 100%;
          max-width: 1536px;
          padding: 4rem 0 0;
          box-sizing: border-box;
  
          .product-image-container {
            > .cmp-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .title {
                margin-top: -2.22rem;
                > .cmp-title {
                  margin-top: 0rem;
                  margin-bottom: 0rem;
                }
              }
            }
          }
          .product-image-container:not(.product-image-container-text-overlay)
            .product-image {
            padding-bottom: 2.22rem;
            box-sizing: border-box;
          }
  
          .product-image {
            max-height: 100%;
            .cmp-adaptive-image.cmp-image {
              height: 100%;
              picture {
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }